import { ListWithTitle } from "./ListWithTitle";
import { CopyWrite } from "./CopyWrite";
import { APP_NAME } from "~/libs/APP_NAME";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";
import { getSitePrivacyPolicyPagePath } from "~/routes/site.privacy_policy/path";
import { getSiteTermsOfServicePagePath } from "~/routes/site.terms_of_service/path";
import { getSiteLegalNoticePagePath } from "~/routes/site.legal_notice/path";
import { getUpgradePagePath } from "~/routes/site.upgrade._index/path";
import { getSiteContactPagePath } from "~/routes/site.contact/path";

export function AppFooter(): JSX.Element {
  const dict = useDictionary();
  return (
    <footer className="flex w-full flex-col gap-2 bg-primary-50 px-8 pb-4 pt-12">
      <div className="flex flex-col justify-center gap-x-24 gap-y-8 sm:flex-row">
        <div className="font-bold">{APP_NAME}</div>

        <ListWithTitle
          title={dict.service_information}
          list={[
            // いつか…最初はいいや
            // {
            //   title: dict.news,
            //   href: "/TODO",
            // },
            {
              title: dict.upgrade,
              href: getUpgradePagePath(),
            },
          ]}
        />

        <ListWithTitle
          title={dict.support}
          list={[
            // いつか…最初はいいや
            // {
            //   title: dict.help,
            //   href: "/TODO",
            // },
            {
              title: dict.contact,
              href: getSiteContactPagePath(),
            },
          ]}
        />

        <ListWithTitle
          title={dict.legal_information}
          list={[
            {
              title: dict.terms_of_service,
              href: getSiteTermsOfServicePagePath(),
            },
            {
              title: dict.legal_notice,
              href: getSiteLegalNoticePagePath(),
            },
            {
              title: dict.privacy_policy,
              href: getSitePrivacyPolicyPagePath(),
            },
          ]}
        />
      </div>
      <div className="mt-10 flex justify-center text-center text-xs text-muted-foreground">
        <CopyWrite appName={APP_NAME} fromYear={2024} />
      </div>
    </footer>
  );
}
